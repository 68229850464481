import { Button, Card, List, Tooltip, message, notification } from 'antd';
import React from 'react';
import './PatientTurnsList.scss';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { deleteTurnApi } from '../../api/turn';
import { getAccessTokenApi } from '../../api/auth';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { onPatientTurnsRead } from '../../actions';

export default function PatientTurnsList() {
  const { turns, loadingTurns } = useSelector((store) => store.turnPatientData);
  const { user } = useSelector((store) => store.userData);
  const { organizationPhone } = useSelector((store) => store.tenantData);

  const dispatch = useDispatch();

  const deleteTurn = (turnId, shiftId) => {
    const token = getAccessTokenApi();
    message.loading('Cancelando turno, aguarde un momento...', 0);
    deleteTurnApi({ token, turnId, shiftId, patientId: user._id })
      .then((response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
          message.destroy();
        } else {
          notification['success']({ message: response.message });
          dispatch(onPatientTurnsRead(user?._id));
          message.destroy();
        }
      })
      .catch((err) => notification['error']({ message: 'Error del servidor' }));
  };

  return (
    <Card
      className="card-patient"
      title="Mis Turnos"
      extra={
        <Tooltip
          title="Solo puede solicitar un maximos de tres turnos. Toda la informacion en cuanto a reprogramación o
        cancelacion de sus turnos sera informada via email, por favor estar
        atento a todas las notificaciones enviadas, ante cualquier duda
        comunicarse con la intitución."
        >
          <InfoCircleOutlined style={{ color: '#fff' }} />
        </Tooltip>
      }
    >
      <List
        itemLayout="horizontal"
        dataSource={turns}
        size="small"
        loading={loadingTurns}
        renderItem={(turn) => (
          <>
            <div className="card-patient__date-cel">
              <span>{dayjs(turn.shifts.hourStart).format('DD/MM HH:mm')}</span>
            </div>
            <List.Item
              actions={[
                <Tooltip
                  title={
                    dayjs(turn.shifts.hourStart)
                      .startOf('hours')
                      .diff(dayjs().startOf('hours'), 'hours') <= 8
                      ? `Para cancelar un turno necesita hacerlo con 8hs de antelacion. Comunicarse al ${organizationPhone}`
                      : 'Cancelar turno'
                  }
                >
                  <Button
                    type="link"
                    disabled={
                      dayjs(turn.shifts.hourStart)
                        .startOf('hours')
                        .diff(dayjs().startOf('hours'), 'hours') <= 8
                    }
                    onClick={() => deleteTurn(turn?._id, turn.shifts._id)}
                  >
                    <DeleteOutlined style={{ color: 'red' }} />
                  </Button>
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <div className="card-patient__date">
                    <span>
                      {dayjs(turn.shifts.hourStart).format('DD/MM HH:mm')}
                    </span>
                  </div>
                }
                title={
                  turn.nameDoctor[0]
                    ? `Doctor: ${turn.nameDoctor[0]} ${turn.lastnameDoctor[0]}`
                    : `Estudio ${turn.shifts.studies[0]}`
                }
                description={turn?.medicalSpeciality[0]}
              />
            </List.Item>
          </>
        )}
        locale={{ emptyText: 'No hay turnos' }}
      />
    </Card>
  );
}
