import LayoutAdmin from '../layouts/LayoutAdmin';
import Home from '../pages/Home/Home';
import SignIn from '../pages/SignIn';
import Error404 from '../pages/Error404';
import PdfReports from '../pages/PdfReports';
import Turns from '../pages/Turns';
import TurnDetail from '../components/TurnDetail/TurnDetail';

const routes = [
  {
    path: '/',
    exact: false,
    component: LayoutAdmin,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },

      {
        path: '/login',
        exact: true,
        component: SignIn,
      },
      {
        path: '/patient-studies/:id/:name?',
        exact: true,
        component: PdfReports,
      },
      {
        path: '/turns',
        exact: true,
        component: Turns,
      },
      {
        path: '/:origen',
        exact: true,
        component: Turns,
      },
      {
        path: '/turn-detail',
        exact: true,
        component: TurnDetail,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
