import React, { useState } from 'react';
import { Row, Col, Tabs, Button } from 'antd';
import { useSelector } from 'react-redux';
import RegisterForm from '../RegisterForm';
import './MainBanner.scss';
import LoginForm from '../LoginForm/LoginForm';
import { Link } from 'react-router-dom';

export default function MainBanner() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const { TabPane } = Tabs;
  const { organization, tenant, onlineTurns, tenantId } = useSelector(
    (store) => store.tenantData
  );
  return (
    <div className="main-banner">
      <div className="main-banner__dark" />
      <Row>
        <Col md={4} />
        <Col md={20}>
          <Row>
            <Col xs={16} md={10}>
              <h2>
                Bienvenidos <br /> Portal de Pacientes
                <br />
                {organization}
              </h2>
            </Col>
            <Col xs={8} md={10}>
              <div className="main-banner__logo">
                <img
                  src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo.png`}
                  alt="Logo"
                />
              </div>
            </Col>
          </Row>

          <h3>
            Desde aqui vas a poder sacar/consultar tus turnos y visualzar tus
            estudios
          </h3>
          <Col xs={24} lg={12}>
            <div className="sign-in-content__tabs">
              <Tabs size="large" type="card">
                <TabPane tab={<span>Entrar</span>} key="1">
                  {onlineTurns && (
                    <a
                      href={`https://portalturnos.ampinasoft.com/${tenantId}`}
                      type="primary"
                    >
                      Solicitar Turno sin usuario
                    </a>
                  )}
                  <LoginForm
                    forgotPassword={forgotPassword}
                    setForgotPassword={setForgotPassword}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      setForgotPassword(!forgotPassword);
                    }}
                  >
                    {forgotPassword
                      ? 'Volver al login'
                      : 'Recuperar mi Contraseña'}
                  </Button>
                </TabPane>
                <TabPane tab={<span>Registrarme en Portal</span>} key="2">
                  <RegisterForm />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Col>
        <Col md={4} />
      </Row>
    </div>
  );
}
