import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Spin from '../Spin';
export default function PdfViewer({ pdf }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (!pdf) {
    return <p>Seleccione un pdf</p>;
  }

  return (
    <div
      style={{
        borderColor: '#002a52',
        overflowY: 'scroll',
        height: '80vh',
      }}
    >
      <Document
        file={pdf}
        loading={<Spin />}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => 'Cargue nuevamente'}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={window.screen.width < 500 ? 0.8 : 1.2}
          />
        ))}
      </Document>
    </div>
  );
}
