import {
  ON_FLOORS_READ,
  ON_FLOORS_READING,
  ON_PRIMARY_READING,
  ON_PRIMARY_READ,
} from './types';
import { getFloors, getPrimaryData } from '../api/tenantConfiguration';
import { getAccessTokenApi } from '../api/auth';
import { notification } from 'antd';

export const onFloorsRead = () => async (dispatch) => {
  dispatch({ type: ON_FLOORS_READING });
  const token = await getAccessTokenApi();
  return await getFloors(token)
    .then((response) => {
      if (response.error) {
      }
      dispatch({
        type: ON_FLOORS_READ,
        payload: {
          floors: response.tenantData?.floors,
          organization: response.tenantData?.name,
          tenant: response.tenantData?.tenant,
          logos: response.tenantData?.logos,
          studies: response.tenantData?.studies,
          organizationType: response.tenantData?.organizationType,
          down: response.tenantData?.down,
          tenantType: response.tenantData?.tenantType,
          tenantId: response.tenantData?.tenantId,
          tenantLogo: response.tenantData?.logo,
          tenantTimezone: response.tenantData?.timezone,
        },
      });
    })
    .catch(() => notification['info']({ message: 'Error' }));
};

export const onPrimaryDataRead =
  (tenant = undefined) =>
  async (dispatch) => {
    dispatch({ type: ON_PRIMARY_READING });
    return await getPrimaryData(tenant)
      .then((response) => {
        if (response.error) {
        }
        dispatch({
          type: ON_PRIMARY_READ,
          payload: {
            organization: response.tenantData?.name,
            tenant: response.tenantData?.tenant,
            tenantId: response.tenantData?.tenantId,
            logos: response.tenantData?.logos,
            organizationType: response.tenantData?.organizationType,
            organizationPhone: response.tenantData?.phone,
            medicalSpecialities: response.tenantData?.medicalSpecialities,
            studies: response.tenantData?.studies,
            down: response.tenantData?.down,
            multitenant: response.tenantData?.multitenant,
            onlineTurns: response.tenantData?.onlineTurns,
            portalActive: response.tenantData?.portalActive,
            tenantLogo: response.tenantData?.logo,
            direction: response.tenantData?.direction,
            tenantTimezone: response.tenantData?.timezone,
            countryCode: response.tenantData?.countryCode,
          },
        });
      })
      .catch((err) => console.log(err));
  };
