import {
  ON_FLOORS_READ,
  ON_FLOORS_READING,
  ON_PRIMARY_READING,
  ON_PRIMARY_READ,
} from '../actions/types';

const INITIAL_STATE = {
  tenant: '',
  organization: '',
  organizationType: '',
  organizationPhone: '',
  direction: '',
  logos: 1,
  floors: [],
  studies: [],
  medicalSpecialities: [],
  down: false,
  multitenant: false,
  tenantType: '',
  tenantId: '',
  tenantTimezone: 'America/Argentina/Buenos_Aires',
  countryCode: '+54',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_FLOORS_READING:
      return { ...state, loading: true };
    case ON_FLOORS_READ:
      return {
        ...state,
        floors: action.payload.floors,
        organization: action.payload.organization,
        tenant: action.payload.tenant,
        logos: action.payload.logos,
        studies: action.payload.studies.filter((e) => e.scheduleConfig.public),
        organizationType: action.payload.organizationType,
        down: action.payload.down || false,
        tenantType: action.payload.tenantType,
        tenantId: action.payload.tenantId,
        tenantLogo: action.payload.tenantLogo,
        tenantTimezone: action.payload?.tenantTimezone,

        loading: false,
      };
    case ON_PRIMARY_READING:
      return { ...state, loading: true };
    case ON_PRIMARY_READ:
      return {
        ...state,
        organization: action.payload.organization,
        tenant: action.payload.tenant,
        tenantId: action.payload.tenantId,
        logos: action.payload.logos,
        organizationType: action.payload.organizationType,
        medicalSpecialities: action.payload.medicalSpecialities,
        down: action.payload.down || false,
        multitenant: action.payload.multitenant || false,
        organizationPhone: action.payload.organizationPhone,
        direction: action.payload.direction,
        onlineTurns: action.payload.onlineTurns,
        studies: action.payload?.studies?.filter(
          (e) => e.scheduleConfig.public
        ),
        portalActive: action.payload.portalActive || false,
        tenantLogo: action.payload.tenantLogo,
        tenantTimezone: action.payload?.tenantTimezone,
        countryCode: action.payload?.countryCode,
        loading: false,
      };

    default:
      return state;
  }
};
