import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Select } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './RegisterForm.scss';
import { getTenants } from '../../api/tenantConfiguration';
import { signUpApiPortal } from '../../api/portal';

export default function RegisterForm() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [tenants, setTenants] = useState([]);

  const [registerForm] = Form.useForm();

  const { organizationPhone, multitenant } = useSelector(
    (store) => store.tenantData
  );

  useEffect(() => {
    window.location.hostname.includes('ambulatorio') &&
      getTenants().then((response) => setTenants(response.tenants));
  }, []);

  const register = async (values) => {
    setLoading(true);

    if (values.password !== values.repeatPassword) {
      message.error('Las contraseñas tienen que ser iguales');
    } else {
      const result = await signUpApiPortal(values);
      if (!result.ok) {
        message.error(result.message, 2).then(() => message.destroy());
      } else {
        result?.user?.verifed !== 'FORGOT' && setEmail(result?.user?.email);
        message.success(
          result?.user?.verifed === 'FORGOT'
            ? result.message
            : `${result.message}. Debe validar su cuenta en su mail ${result?.user?.email}`
        );
        registerForm.resetFields();
      }
    }
    setLoading(false);
  };

  return (
    <div>
      {!email && (
        <div>
          <p style={{ fontSize: '9pt' }}>
            Para poder crear una cuenta en la portal debera ser paciente de la
            institución y tener un mail cargado por la misma. Puede verificar si
            cumple con los requisitos intentando crear una cuenta.
          </p>
          <p style={{ fontSize: '9pt' }}>
            Ante cualquier duda comunicarse con la institución o medico.{' '}
            <a href={`tel: ${organizationPhone}`}>{organizationPhone}</a>
          </p>
        </div>
      )}
      {email && (
        <div>
          <h4 style={{ color: 'red' }}>
            Mail de verificacion enviado a: {email}
          </h4>
          <p>
            En caso de no tener acceso a ese mail comunicarse al:{' '}
            <a href={`tel: ${organizationPhone}`}>{organizationPhone}</a>
          </p>
        </div>
      )}
      <Form className="register-form" form={registerForm} onFinish={register}>
        {multitenant && (
          <Form.Item
            name="tenantId"
            rules={[{ required: true, message: 'Debe elegir su medico' }]}
          >
            <Select
              placeholder="Seleccione el medico"
              allowClear
              inputMode="none"
            >
              {tenants?.map((tenant) => (
                <Select.Option value={tenant.tenantId}>
                  {tenant.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="dni"
          initialValue=""
          rules={[
            {
              required: true,
              message: 'Debe ingresar su dni',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
            placeholder="DNI"
            className="register-form__input"
            type="number"
            inputMode="numeric"
          />
        </Form.Item>
        <Form.Item
          name="password"
          initialValue=""
          rules={[{ required: true, message: 'Debe ingresar una contraseña' }]}
          help="Debe tener 6 caracteres o mas"
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
            placeholder="Contraseña"
            className="register-form__input"
            minLength={6}
          />
        </Form.Item>
        <Form.Item
          name="repeatPassword"
          initialValue=""
          rules={[{ required: true, message: 'Debe ingresar una contraseña' }]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
            placeholder="Repetir contraseña"
            className="register-form__input"
            minLength={6}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            style={{ width: '100%' }}
            className="register-form__button"
            disabled={loading}
            loading={loading}
          >
            Registrarme
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
