import React from 'react';
import { Spin as SpinAntd } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Spin() {
  return (
    <SpinAntd
      style={{ width: '100%', padding: '200px 0' }}
      size="large"
      indicator={<LoadingOutlined />}
    />
  );
}
