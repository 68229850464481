import { ON_TURN_PATIENT_READING, ON_TURN_PATIENT_READ } from './types';
import { getAccessTokenApi } from '../api/auth';
import { getPatientTurnsPortalApi } from '../api/portal';
import { notification } from 'antd';

export const onPatientTurnsRead = (patientId) => async (dispatch) => {
  dispatch({ type: ON_TURN_PATIENT_READING });
  const token = await getAccessTokenApi();

  return getPatientTurnsPortalApi({ token, patientId })
    .then(async (response) => {
      await dispatch({
        type: ON_TURN_PATIENT_READ,
        payload: {
          turnsData: response.turnsData,
          turnsQuantity: response.quantity,
        },
      });
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};
