import { basePath, apiVersion } from './config';

export async function getUserDataApi(token) {
  const url = `${basePath}/${apiVersion}/get-data`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();

    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function signUpApi(data) {
  const url = `${basePath}/${apiVersion}/sign-up`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    if (result_1.user) {
      return { ok: true, message: 'Usuario creado correctamente' };
    }
    return { ok: false, message: result_1.message };
  } catch (err) {
    return { ok: false, message: err.message };
  }
}

export async function signInApi(data) {
  const url = `${basePath}/${apiVersion}/sign-in`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getUsersApi(token) {
  const url = `${basePath}/${apiVersion}/users`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getUsersActiveApi(token, status) {
  const url = `${basePath}/${apiVersion}/users-active?active=${status}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getUsersTypeApi(tenantId) {
  const url = `${basePath}/${apiVersion}/users-type-portal?tenant=${tenantId}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function uploadSignatureApi(token, signature, userId) {
  const url = `${basePath}/${apiVersion}/upload-signature/${userId}`;

  const formData = new FormData();

  formData.append('signature', signature, signature.name);

  const params = {
    method: 'PUT',
    body: formData,
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getSignatureApi(signatureName, token) {
  const url = `${basePath}/${apiVersion}/get-signature/${signatureName}`;

  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    return response;
  } catch (err) {
    return err.message;
  }
}

export async function updateUserApi(token, user, userId) {
  const url = `${basePath}/${apiVersion}/update-user/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(user),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function activateUserApi(token, userId, status) {
  const url = `${basePath}/${apiVersion}/activate-user/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({
      active: status,
    }),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1.message;
  } catch (err) {
    return err.message;
  }
}

export async function changeSizeSlotApi({ token, userId, data }) {
  const url = `${basePath}/${apiVersion}/change-size-slot/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function deleteUserApi(token, userId) {
  const url = `${basePath}/${apiVersion}/delete-user/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1.message;
  } catch (err) {
    return err.message;
  }
}

export async function signUpAdminApi(token, user) {
  const url = `${basePath}/${apiVersion}/sign-up-admin`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(user),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1.message;
  } catch (err) {
    return err.message;
  }
}

export async function getFavouritePatients(token) {
  const url = `${basePath}/${apiVersion}/favourite-patients`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function addFavouritePatients(token, patientId, type) {
  const url = `${basePath}/${apiVersion}/favourite-patient/${patientId}&${type}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function updateConfigScheduleApi({ token, userId, data }) {
  const url = `${basePath}/${apiVersion}/config-schedule/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function updateExcludeDayScheduleApi({ token, userId, data }) {
  const url = `${basePath}/${apiVersion}/config-schedule-exclude-day/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function changeStatusScheduleApi({ token, userId, data }) {
  const url = `${basePath}/${apiVersion}/config-schedule-status/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}
