import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  CalendarOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import './MenuSider.scss';
import { useSelector } from 'react-redux';

function MenuSider(props) {
  const { menuCollapsed, setMenuCollapsed, location } = props;

  const { Sider } = Layout;
  const { user } = useSelector((store) => store.userData);

  return (
    <Sider
      className="admin-sider"
      collapsed={menuCollapsed}
      breakpoint="lg"
      onBreakpoint={(broken) => {
        setMenuCollapsed(broken);
      }}
    >
      <Menu
        theme="dark"
        mode="vertical"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
        style={{ zIndex: 1 }}
      >
        <Menu.Item key="/">
          <Link to={'/'}>
            <HomeOutlined /> <span className="navText">Home</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/turns">
          <Link to={'/turns'}>
            <CalendarOutlined /> <span className="navText">Buscar Turnos</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={`/patient-studies/${user._id}/${user.name}`}>
          <Link to={`/patient-studies/${user._id}/${user.name}`}>
            <FilePdfOutlined />
            <span className="patient-actions_title">Mis Estudios</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default withRouter(MenuSider);
