import { basePath, apiVersion } from './config';

export async function getPatientStudiesApi(id, token) {
  const url = `${basePath}/${apiVersion}/patient-studies/${id}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getSocialWorksApi(tenantId) {
  const url = `${basePath}/${apiVersion}/social-work-portal?tenant=${tenantId}`;

  const params = {
    method: 'GET',
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
