import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  List,
  Button,
  Tag,
  notification,
  Tooltip,
  Divider,
  Input,
  Form,
  Row,
  Col,
  Select,
  message,
  Anchor,
  Radio,
} from 'antd';
import dayjs from 'dayjs';
import './ListTurns.scss';
import { useSelector } from 'react-redux';
import { getAccessTokenApi } from '../../../api/auth';
import {
  addTurnApi,
  addTurnPortalApi,
  deleteTurnApi,
  updateTurnApi,
  updateTurnPortalApi,
} from '../../../api/turn';
import Modal from '../../Modal/Modal';
import { getSocialWorksApi } from '../../../api/patient';
import Spin from '../../Spin';
import { countryCodes } from '../../../utils/constants';
import { getServicesApi } from '../../../api/service';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ListTurns(props) {
  const {
    slots,
    turnUser,
    turns,
    setReload,
    turnId,
    size,
    turnType,
    date,
    setTurnDetail,
    scheduleConfig,
  } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [socialWorks, setSocialWorks] = useState();
  const [servicesData, setServicesData] = useState([]);

  const { _id, socialWork, email } = useSelector(
    (store) => store.userData.user
  );
  const { turnsQuantity } = useSelector((store) => store.turnPatientData);
  const { tenantId, tenantTimezone, countryCode } = useSelector(
    (store) => store.tenantData
  );
  const [patientForm] = Form.useForm();

  const quantitySlots = slots.filter(
    (slot) =>
      dayjs(slot.hourStart).tz(tenantTimezone).unix() >
        dayjs().tz(tenantTimezone).add(4, 'hours').unix() && !slot?.second
  );

  const quantityTurns = [
    ...new Set(turns?.map((item) => item?.hourStart)),
  ].filter(
    (data) =>
      dayjs(data).tz(tenantTimezone).unix() >
      dayjs().tz(tenantTimezone).add(4, 'hours').unix()
  );

  useEffect(() => {
    getSocialWorksApi(tenantId)
      .then((response) => {
        setSocialWorks(response?.socialWorks);
      })
      .catch((err) => console.log(err));

    getServicesApi(tenantId)
      .then((response) => {
        setServicesData(response?.services);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getServicesApi(tenantId)
      .then((response) => {
        setServicesData(response?.services);
      })
      .catch((err) => console.log(err));
  }, [turnUser]);

  async function addTurn(turn) {
    const token = await getAccessTokenApi();

    if (!_id) {
      let onFinish = async (values) => {
        setLoading(true);
        message.loading('Solicitando turno, aguarde un momento...', 0);

        values.socialWork =
          values.socialWork === false ? '' : values.socialWork;

        let notificationPhone = {
          countryCode: values?.countryCode,
          regionCode: values?.regionCode,
          number: values?.number,
        };

        let services = servicesData?.filter((service) => {
          if (values?.studiesTypes?.find((e) => e === service.name)) {
            return { service: service.name, price: service?.price };
          }
        });

        let price =
          services?.reduce(
            (accumulator, currentValue) =>
              (accumulator || 0) + parseFloat(currentValue.price || 0),
            0
          ) || null;

        turnId
          ? await updateTurnPortalApi({
              tenantId,
              turnId,
              dataTurn: {
                hourStart: turn?.hourStart,
                socialWork: values.socialWork,
                name: values.name,
                dni: values.dni,
                notificationPhone: notificationPhone,
                email: values.email,
                price: price,
                portal: true,
                studies: services,
                description: values?.description,
                turnType,
                user: turnUser,
              },
            })
              .then(async (response) => {
                message.destroy();

                if (response?.code !== 200) {
                  await message.warning(response.message, 5);

                  setReload(true);
                } else {
                  await message.success(response.message, 1);
                  setTurnDetail({
                    hour: dayjs(turn.hourStart),
                    services: services || null,
                  });
                }
              })
              .catch((err) =>
                notification['error']({ message: 'Error del servidor' })
              )
          : await addTurnPortalApi({
              tenantId,
              data: {
                date: date,
                user: turnUser,
                sizeSlot: size,
                turnType,
                dataTurn: {
                  hourStart: turn?.hourStart,
                  socialWork: values.socialWork,
                  name: values.name,
                  dni: values.dni,
                  notificationPhone: notificationPhone,
                  email: values.email,
                  price: price,
                  portal: true,
                  studies: services,
                  description: values?.description,
                },
              },
            })
              .then(async (response) => {
                if (response?.code !== 200) {
                  await message.warning(response.message, 5);

                  setReload(true);
                } else {
                  await message.success(response.message, 1);
                  setTurnDetail({
                    hour: dayjs(turn.hourStart),
                    services: services || null,
                  });
                }
              })
              .catch((err) =>
                notification['error']({ message: 'Error del servidor' })
              );
        message.destroy();

        setIsVisibleModal(false);
        setLoading(false);
      };
      setIsVisibleModal(true);
      setModalTitle(`Nuevo Turno ${dayjs(turn?.hourStart).format('HH mm')} hs`);
      setModalContent(
        <Form onFinish={onFinish} form={patientForm}>
          <Row gutter={24}>
            <Col xs={24} md={8} lg={8}>
              <Form.Item
                name="dni"
                rules={[
                  {
                    required: true,
                    message: 'Debe cargar el dni. Sin puntos',
                  },
                ]}
                label="DNI"
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="34256389"
                  type="number"
                  inputMode="numeric"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={16} lg={16}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Debe cargar el Apellido y Nombre',
                  },
                ]}
                label="Apellido y Nombre"
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Apellido Nombre"
                  style={{ textTransform: 'capitalize' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <Form.Item
                name="socialWork"
                rules={[
                  { required: true, message: 'Debe cargar la obra social' },
                ]}
                label="Obra social"
                labelCol={{ span: 24 }}
              >
                <Select
                  placeholder="Obra Social"
                  showSearch
                  optionFilterProp="children"
                >
                  {socialWorks &&
                    socialWorks.map((socialWork, index) => (
                      <Select.Option
                        key={index}
                        value={socialWork.name}
                        style={{ textTransform: 'uppercase' }}
                      >
                        {socialWork.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <Form.Item
                name="email"
                label="Correo electronico"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message:
                      'Debe cargar un mail valido. (sin espacios al final)',
                  },
                ]}
                tooltip="En caso de que su turno sea cancelado recibira una notificación via mail"
              >
                <Input placeholder="Correo electronico" inputMode="email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={8}>
              <Form.Item label="Teléfono de contacto" labelCol={{ span: 24 }}>
                <Row>
                  <Col xs={12} xl={6}>
                    <Form.Item
                      name="countryCode"
                      noStyle
                      initialValue={countryCode}
                    >
                      <Select style={{ fontSize: '8pt' }}>
                        {countryCodes?.map((country) => (
                          <Select.Option value={country.dial_code}>
                            {country.emoji}
                            {country.dial_code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} xl={6}>
                    <Form.Item
                      name="regionCode"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: 'Debe completar el codigo de area. Ej 385',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Cod Area"
                        help="385"
                        maxLength={4}
                        type="number"
                        inputMode="tel"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      name="number"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: 'Debe completar su nro. Ej 4889915',
                        },
                      ]}
                    >
                      <Input
                        addonBefore="15"
                        placeholder="Nro Tel."
                        maxLength={7}
                        type="number"
                        inputMode="tel"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            {scheduleConfig.service?.length > 0 ? (
              <Col xs={24}>
                <Form.Item
                  name="studiesTypes"
                  label="Servicios"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Seleccione servicios.. (*)"
                    inputMode="none"
                  >
                    {servicesData?.map((service) => {
                      if (
                        scheduleConfig.service?.find((e) => e === service._id)
                      ) {
                        return (
                          <Select.Option key="studie" value={service?.name}>
                            {service?.name}
                          </Select.Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              turnType === 'medico' && (
                <Col xs={24} md={8} lg={8}>
                  <Form.Item
                    name="description"
                    label="Tipo de turno (opcional)"
                    labelCol={{ span: 24 }}
                  >
                    <Radio.Group>
                      <Radio value="Primera Vez">Primera Vez</Radio>
                      <Radio value="Consulta">Consulta</Radio>
                      <Radio value="Consulta + Practica">
                        Consulta + Practica
                      </Radio>{' '}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )
            )}
          </Row>

          <Row gutter={24} style={{ textAlign: 'center' }}>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="submit"
                  style={{ width: '100%', marginTop: '10px' }}
                  disabled={loading}
                  loading={loading}
                >
                  Solicitar turno
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    } else {
      message.loading('Solicitando turno, aguarde un momento...', 0);

      turnId
        ? await updateTurnApi({
            token,
            turnId,
            dataTurn: {
              hourStart: turn?.hourStart,
              patient: _id,
              socialWork: socialWork,
              email: email,
              portal: true,
              studies: turnType !== 'medico' ? [{ name: turnType }] : [],
            },
          })
            .then((response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                notification['success']({ message: response.message });
                setReload(true);
              }
            })
            .catch((err) =>
              notification['error']({ message: 'Error del servidor' })
            )
        : await addTurnApi({
            token,
            data: {
              date: date,
              user: turnUser,
              sizeSlot: size,
              dataTurn: {
                hourStart: turn?.hourStart,
                patient: _id,
                socialWork: socialWork,
                email: email,
                portal: true,
                studies: turnType !== 'medico' ? [{ name: turnType }] : [],
                turnType: turnType,
              },
            },
          })
            .then((response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                notification['success']({ message: response.message });
                setReload(true);
              }
            })
            .catch((err) =>
              notification['error']({ message: 'Error del servidor' })
            );
      message.destroy();
    }
  }

  const deleteTurn = (shiftId) => {
    const token = getAccessTokenApi();
    message.loading('Cancelando turno, aguarde un momento...', 0);

    deleteTurnApi({ token, turnId, shiftId, patientId: _id })
      .then((response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
          message.destroy();
        } else {
          notification['success']({ message: response.message });
          setReload(true);
          message.destroy();
        }
      })
      .catch((err) => {
        notification['error']({ message: 'Error del servidor' });
        message.destroy();
      });
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <span>Solicitando turno, aguarde un momento...</span>
        <Spin />
      </div>
    );
  }

  if (slots?.length > 0 && quantitySlots?.length <= quantityTurns?.length) {
    return (
      <Divider style={{ backgroundColor: '#f8f9fa' }}>Agenda completa</Divider>
    );
  }

  return (
    <div className="turns-list">
      <Anchor offsetTop={5}>
        <h3 className="title-date">
          {dayjs(date).format('dddd')} {dayjs(date).format('D')} de{' '}
          {dayjs(date).format('MMMM')}
        </h3>
      </Anchor>

      <List
        itemLayout="horizontal"
        dataSource={slots}
        size="small"
        renderItem={(slot, index) => (
          <Slot
            slot={slot}
            addTurn={addTurn}
            deleteTurn={deleteTurn}
            data={turns?.filter(
              (turn) =>
                (turn.hourStart === slot.hourStart ||
                  (dayjs(turn.hourStart).isBefore(
                    slots[index + 1]?.hourStart
                  ) &&
                    dayjs(turn.hourStart).isAfter(slot?.hourStart))) &&
                !slot.exclude &&
                !turn.softDelete
            )}
            size={size}
            patientId={_id}
            turnsQuantity={turnsQuantity}
            date={date}
            user={_id}
            tenantTimezone={tenantTimezone}
          />
        )}
        locale={{ emptyText: 'No hay mas turnos.' }}
        loading={
          !slots && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === 'Cancelar turno' ? '50%' : '80%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Slot(props) {
  const {
    slot,
    addTurn,
    data,
    deleteTurn,
    size,
    patientId,
    turnsQuantity,
    date,
    user,
    tenantTimezone,
  } = props;

  let content = (turn = undefined, index = 0) => {
    return !slot.exclude ? (
      (dayjs().tz(tenantTimezone).add(4, 'hours') <=
        dayjs(slot?.hourStart).tz(tenantTimezone) ||
        dayjs().tz(tenantTimezone) < dayjs(date).tz(tenantTimezone)) &&
        !turn && (
          <>
            <List.Item
              style={
                dayjs() < dayjs(slot?.hourStart).add(size, 'm') &&
                dayjs() >= dayjs(slot?.hourStart)
                  ? { backgroundColor: '#eef7ff' }
                  : { backgroundColor: '#fff' }
              }
              actions={[
                ((!turn && !slot?.cancel) || turn?.patient === patientId) && (
                  <Tooltip
                    title={
                      user && turn?.patient !== patientId && turnsQuantity >= 3
                        ? 'El maximo de turnos que puede sacar es de 3'
                        : user &&
                          turn?.patient === patientId &&
                          dayjs(turn?.hourStart)
                            .startOf('hours')
                            .diff(dayjs().startOf('hours'), 'hours') <= 8 &&
                          'Para eliminar un turno necesita hacerlo con 8hs de antelacion.'
                    }
                  >
                    <Button
                      type="link"
                      disabled={
                        user && turn?.patient === patientId
                          ? dayjs(turn?.hourStart)
                              .startOf('hours')
                              .diff(dayjs().startOf('hours'), 'hours') <= 8
                          : turnsQuantity >= 3
                      }
                      onClick={() =>
                        user && turn?.patient === patientId
                          ? deleteTurn(turn?._id)
                          : addTurn(slot)
                      }
                    >
                      {user && turn?.patient === patientId ? (
                        <>
                          <DeleteOutlined style={{ color: 'red' }} />
                          <span style={{ color: 'red' }}>
                            Cancelar Turno
                          </span>{' '}
                        </>
                      ) : (
                        <>
                          <PlusOutlined />
                          <span>Solicitar Turno</span>
                        </>
                      )}
                    </Button>
                  </Tooltip>
                ),
              ]}
            >
              <List.Item.Meta
                avatar={
                  <div
                    className="turns-list__date"
                    style={index > 0 ? { backgroundColor: '#e31b23' } : {}}
                  >
                    {index > 0 && <span>ST </span>}
                    <span>
                      {dayjs(slot?.hourStart)
                        .tz(tenantTimezone)
                        .format('HH:mm')}
                    </span>
                  </div>
                }
                title={
                  (turn || slot?.cancel) && (
                    <Tag
                      color={
                        turn?.patient === patientId
                          ? 'green'
                          : turn
                          ? 'red'
                          : 'default'
                      }
                      icon={
                        turn?.patient === patientId ? (
                          <CheckCircleOutlined />
                        ) : turn ? (
                          <MinusCircleOutlined />
                        ) : (
                          <ExclamationCircleOutlined />
                        )
                      }
                    >
                      {turn?.patient === patientId
                        ? 'Mi Turno'
                        : turn
                        ? 'Ocupado'
                        : 'No disponible'}
                    </Tag>
                  )
                }
              />
            </List.Item>
          </>
        )
    ) : (
      <List.Item style={{ backgroundColor: '#f8f9fa' }}>
        <List.Item.Meta
          title={
            <Divider style={{ backgroundColor: '#f8f9fa' }}>
              {slot.second ? 'Segundo Turno' : 'Día Excluído'}
            </Divider>
          }
        />
      </List.Item>
    );
  };

  if (!data || data?.length === 0) {
    return content();
  } else {
    return data?.map((turn, index) => {
      return content(turn, index);
    });
  }
}
