import React from 'react';
import '../../pages/Turns/Turns.scss';
import { useSelector } from 'react-redux';
import { Card, Descriptions, Result } from 'antd';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  MedicineBoxOutlined,
  SolutionOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function TurnDetail({ turnDetail, doctor, turnType }) {
  const { tenant, organization, direction, organizationPhone, tenantTimezone } =
    useSelector((store) => store.tenantData);

  return (
    <div className="online" style={{ height: '100vh' }}>
      <Card title="Detalle del turno reservado">
        <Result
          status="success"
          title={organization}
          subTitle={<p>Turno reservado con éxito.</p>}
          extra={
            <>
              <Descriptions column={{ xs: 1 }}>
                <Descriptions.Item
                  label={
                    <>
                      <CalendarOutlined />
                      <span>Día</span>
                    </>
                  }
                >
                  <span>
                    {dayjs(turnDetail?.hour).format('dddd')}{' '}
                    {dayjs(turnDetail?.hour).format('D')} de{' '}
                    {dayjs(turnDetail?.hour).format('MMMM')}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <>
                      <ClockCircleOutlined />
                      <span>Hora</span>
                    </>
                  }
                >
                  <span>
                    <span>
                      {dayjs(turnDetail?.hour)
                        .tz(tenantTimezone)
                        .format('HH:mm')}{' '}
                      hs
                    </span>
                  </span>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <>
                      <CheckCircleOutlined />
                      <span>Estado</span>
                    </>
                  }
                >
                  <span>
                    <span>Confirmado</span>
                  </span>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <>
                      <EnvironmentOutlined />
                      <span>Dirección</span>
                    </>
                  }
                >
                  <span>{direction}</span>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <>
                      <WhatsAppOutlined />
                      <span>Contacto</span>
                    </>
                  }
                >
                  <a
                    href={`https://wa.me/${organizationPhone}`}
                    target="_blank"
                    referrerPolicy="origin"
                  >
                    {organizationPhone}
                  </a>
                </Descriptions.Item>
                {turnType === 'medico' && (
                  <Descriptions.Item
                    label={
                      <>
                        <MedicineBoxOutlined />
                        <span>Doctor</span>
                      </>
                    }
                  >
                    <span>
                      {doctor?.name} {doctor?.lastname}
                    </span>
                  </Descriptions.Item>
                )}
                {turnDetail?.services.length > 0 && (
                  <Descriptions.Item
                    label={
                      <>
                        <SolutionOutlined />
                        <span>Servicios</span>
                      </>
                    }
                  >
                    <ul style={{ padding: 0, margin: 0, marginLeft: '15px' }}>
                      {turnDetail?.services?.map((service) => (
                        <li>
                          <span>
                            {service.name} -{' '}
                            {service?.price?.toLocaleString('es-AR', {
                              style: 'currency',
                              currency: 'ARS',
                              minimumFractionDigits: 2,
                            }) || ''}
                            <br /> {service?.description}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </Descriptions.Item>
                )}
              </Descriptions>{' '}
              <div className="online-logo">
                <img
                  src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo.png`}
                  alt="Logo"
                />
              </div>
              <div className="online-warn">
                Importante: en caso de no poder acudir, por favor informar a la
                institución. Ante cualquier duda consultar a la institución o
                profesional.
              </div>
            </>
          }
        />
      </Card>
    </div>
  );
}
