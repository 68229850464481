import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../utils/constants';
import { useSelector } from 'react-redux';
import './LoginForm.scss';
import { recoverPasswordApiPortal, signInApiPortal } from '../../api/portal';
import { getTenants } from '../../api/tenantConfiguration';

export default function LoginForm({ forgotPassword, setForgotPassword }) {
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState();
  const [tenants, setTenants] = useState([]);

  const [form] = Form.useForm();
  const { organizationPhone, multitenant } = useSelector(
    (store) => store.tenantData
  );

  useEffect(() => {
    window.location.hostname.includes('ambulatorio') &&
      getTenants().then((response) => setTenants(response.tenants));
  }, []);

  const login = async (values) => {
    setLoading(true);

    if (forgotPassword) {
      const result = await recoverPasswordApiPortal(values);
      setErrorLogin(result.message);
      setLoading(false);
      setForgotPassword(false);
    } else {
      const result = await signInApiPortal(values);

      if (result.message) {
        message.error(result.message);

        setErrorLogin(result.message);
        setLoading(false);
      } else {
        const { accessToken, refreshToken } = result;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);

        message.success('Login correcto');
        window.location.href = '/';
      }
    }
  };
  return (
    <Form className="login-form" form={form} onFinish={login}>
      {multitenant && (
        <Form.Item
          name="tenantId"
          rules={[{ required: true, message: 'Debe elegir su medico' }]}
        >
          <Select
            placeholder="Seleccione el medico"
            allowClear
            inputMode="none"
          >
            {tenants?.map((tenant) => (
              <Select.Option value={tenant.tenantId}>
                {tenant.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name="dni"
        rules={[
          {
            required: true,
            message: 'Debe cargar un dni.',
          },
        ]}
      >
        <Input
          className="login-form__input"
          type="number"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
          placeholder="DNI"
          inputMode="numeric"
        />
      </Form.Item>
      {!forgotPassword && (
        <Form.Item
          className="login-form__input"
          name="password"
          rules={[
            {
              required: true,
              message: 'Debe ingresar una contraseña',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25' }} />}
            placeholder="Contraseña"
          />
        </Form.Item>
      )}
      {errorLogin && (
        <>
          <p style={{ color: 'red' }}>{errorLogin}</p>
          <p style={{ fontSize: '9pt' }}>
            Ante cualquier duda, comunicarse al:{' '}
            <a href={`tel: ${organizationPhone}`}>{organizationPhone}</a>
          </p>
        </>
      )}
      <Form.Item>
        <Button
          className="login-form__button"
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {!forgotPassword ? 'Entrar' : 'Recuperar Contraseña'}
        </Button>
      </Form.Item>
    </Form>
  );
}
