import React from 'react';
import Logo from '../../assets/img/a-iso-blanco-8.png';
import LogoNombre from '../../assets/img/a-blanco-8.png';
import './MenuTop.scss';
import { Button, Row, Tooltip } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { onLogout } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

export default function MenuTop(props) {
  const { menuCollapsed, setMenuCollapsed } = props;

  const { organizationPhone } = useSelector((store) => store.tenantData);
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(onLogout());
  };

  return (
    <div className="menu-top">
      <div className="menu-top-left">
        {!menuCollapsed ? (
          <img className="menu-top-left__logo" src={LogoNombre} alt="logo" />
        ) : (
          <img className="menu-top-left-mini__logo" src={Logo} alt="logo" />
        )}
        <Button type="link" onClick={() => setMenuCollapsed(!menuCollapsed)}>
          {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <Row style={{ right: 0, position: 'absolute' }}>
        <div>
          <PhoneOutlined style={{ color: '#fff' }} />
          <a href={`tel: ${organizationPhone}`} style={{ color: '#fff' }}>
            {' '}
            {organizationPhone}
          </a>
          <Tooltip title="Cerrar sesión">
            <Button type="link" onClick={logoutUser}>
              <PoweroffOutlined />
            </Button>
          </Tooltip>
        </div>
      </Row>
    </div>
  );
}
