import { basePath, apiVersion } from './config';

export async function getFloors(token) {
  const url = `${basePath}/${apiVersion}/floors`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getPrimaryData(tenantId) {
  const url = `${basePath}/${apiVersion}/primary-data?tenant=${tenantId}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getTenants() {
  const url = `${basePath}/${apiVersion}/get-tenants`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
