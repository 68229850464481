import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

export default function Error404() {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Perdon, esta pagina no existe. Si queres obtener tu propio turnero online, dirigite a nuestra web y ponte en contacto con nuestro equipo."
        extra={
          <a href="https://www.ampinasoft.com" type="primary">
            Visita nuestra web
          </a>
        }
      />
    </div>
  );
}
