import {
  ON_TURN_PATIENT_READ,
  ON_TURN_PATIENT_READING,
} from '../actions/types';

const INITIAL_STATE = {
  turns: [],
  turnsQuantity: 0,
  loadingTurns: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_TURN_PATIENT_READING:
      return { ...state, loadingTurns: true };
    case ON_TURN_PATIENT_READ:
      return {
        ...state,
        turns: action.payload?.turnsData,
        turnsQuantity: action.payload?.turnsQuantity,
        loadingTurns: false,
      };

    default:
      return state;
  }
};
