import { combineReducers, createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { loadState, saveState } from './config';
import { ON_LOGOUT_FINISHED } from '../actions/types';
import UserDataReducer from './UserDataReducer';
import TenantDataReducer from './TenantDataReducer';
import TurnPatientReducer from './TurnPatientReducer';
const initialData = loadState() || {};

const reducers = combineReducers({
  userData: UserDataReducer,
  turnPatientData: TurnPatientReducer,
  tenantData: TenantDataReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ON_LOGOUT_FINISHED) {
    state = undefined;
  }

  return reducers(state, action);
};

const store = createStore(
  rootReducer,
  initialData,
  applyMiddleware(ReduxThunk)
);

store.subscribe(function () {
  saveState(store.getState());
});

export default store;
