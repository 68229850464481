import React from 'react';
import { Layout } from 'antd';
import { Redirect } from 'react-router-dom';
import { getAccessTokenApi } from '../../api/auth';
import './SignIn.scss';
import MainBanner from '../../components/MainBanner';

export default function SignIn() {
  if (getAccessTokenApi()) {
    return <Redirect to="/" />;
  }
  return (
    <Layout className="sign-in">
      <MainBanner />
    </Layout>
  );
}
