import React from 'react';
import { Card } from 'antd';
import Spin from '../../Spin';
import dayjs from 'dayjs';
import './PatientList.scss';
import { useSelector } from 'react-redux';
import PatientDescription from '../PatientDescription';

export default function PatientList({ patient }) {
  const { loading } = useSelector((store) => store.userData);

  if (loading) return <Spin />;

  return (
    <>
      <Card
        className="card-patient"
        title={`${patient.name} (${dayjs().diff(
          patient.birthDate,
          'year'
        )} años)`}
      >
        <PatientDescription patient={patient} />
      </Card>
    </>
  );
}
