import React, { useEffect } from 'react';
import {
  onPatientTurnsRead,
  onUserDataRead,
  onFloorsRead,
} from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import './Home.scss';
import { Col, Row } from 'antd';
import '../../scss/primaryButton.scss';
import '../../scss/secondButton.scss';
import '../../scss/linkButton.scss';
import Spin from '../../components/Spin';
import PatientList from '../../components/Ambulatory/PatientList/PatientList';
import PatientTurnsList from '../../components/PatientTurnsList/PatientTurnsList';

export default function Home() {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.userData);
  const { loading } = useSelector((store) => store.userData);

  const { loadingFloors, tenant, logos, organization } = useSelector(
    (store) => store.tenantData
  );
  const { loadingTurns } = useSelector((store) => store.turnPatientData);

  useEffect(() => {
    dispatch(onFloorsRead());
    dispatch(onUserDataRead());
  }, []);

  useEffect(() => {
    user?._id && dispatch(onPatientTurnsRead(user?._id));
  }, [user]);

  if (loading || loadingFloors || loadingTurns) {
    return <Spin />;
  }

  return (
    <div className="home">
      <h1>{`¡ Bienvenido a la Portal de Pacientes de ${organization} !`}</h1>

      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12}>
          <PatientTurnsList />
        </Col>
        <Col xs={24} lg={12}>
          <PatientList patient={user} />
        </Col>
      </Row>

      {logos === 2 ? (
        <div className="home-logo">
          <div className="home-logo-left">
            <img
              src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/clinicayunes/logo.png`}
              alt="Logo"
            />
          </div>
          <div className="home-logo-rigth">
            <img
              src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/clinicayunes/logo1.png`}
              alt="Logo"
            />
          </div>
        </div>
      ) : (
        <div className="home-logo">
          <img
            src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/logo.png`}
            alt="Logo"
          />
        </div>
      )}
    </div>
  );
}
