import {
  ON_USER_READ,
  ON_USER_READING,
  ON_USER_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = {
  user: [],
  incomes: 0,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_USER_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_USER_READING:
      return { ...state, loading: true };
    case ON_USER_READ:
      return {
        ...state,
        user: action.payload.userData,
        incomes: action.payload.incomes,
        loading: false,
      };

    default:
      return state;
  }
};
