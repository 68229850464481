import React from 'react';
import { Descriptions } from 'antd';
import dayjs from 'dayjs';
import './PatientDescription.scss';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

export default function PatientDescription({ patient }) {
  return (
    <Descriptions
      column={{ xs: 1, sm: 1, md: 2, lg: 1, xl: 2 }}
      title={`DNI: ${patient.dni}`}
    >
      <Descriptions.Item label="Obra Social">
        {patient.socialWork}
      </Descriptions.Item>
      <Descriptions.Item label="Afiliado">
        {patient.affiliate}
      </Descriptions.Item>
      <Descriptions.Item label="Fecha de Nacimiento">
        {dayjs(patient.birthDate).format('DD/MM/YYYY')}
      </Descriptions.Item>
      <Descriptions.Item label="Sexo">{patient.sex}</Descriptions.Item>
      <Descriptions.Item
        label="Ciudad de Origen"
        style={{ textTransform: 'capitalize' }}
      >
        {patient.city}
      </Descriptions.Item>
      <Descriptions.Item label="Contacto">
        <span>
          <PhoneOutlined />{' '}
          {patient?.notificationPhone?.number
            ? `${patient?.notificationPhone?.countryCode} - ${patient?.notificationPhone?.regionCode} - ${patient?.notificationPhone?.number}`
            : patient?.phone}
          <br />
          <MailOutlined /> {patient.email}
        </span>
      </Descriptions.Item>
    </Descriptions>
  );
}
