import React from 'react';
import { Col, Card, Row, Button, Tooltip } from 'antd';
import { SwapOutlined } from '@ant-design/icons';

export function PageListAndView(props) {
  const {
    datesTitle,
    order,
    setOrder,
    viewAll,
    componentDate,
    detailTitle,
    data,
    dataAll,
    componentDetail,
    extra,
    extraDate,
  } = props;
  return (
    <div>
      <Row gutter={24}>
        {componentDate ? (
          <Col xs={24}>
            <Card
              title={datesTitle}
              style={{
                borderColor: '#002a52',
                overflowY: 'scroll',
                height: '75vh',
              }}
              extra={
                order ? (
                  <>
                    <Tooltip title="Cambiar orden">
                      <Button
                        type="link"
                        size="large"
                        onClick={() =>
                          order === 'asc' ? setOrder('desc') : setOrder('asc')
                        }
                      >
                        <SwapOutlined />
                      </Button>
                    </Tooltip>
                    <Button type="link" onClick={() => viewAll()}>
                      Ver Todos
                    </Button>
                  </>
                ) : (
                  extraDate && <div> {extraDate} </div>
                )
              }
            >
              {componentDate}
            </Card>
          </Col>
        ) : (
          <Col xs={24}>
            <Card
              title={detailTitle}
              headStyle={{ textAlign: 'center' }}
              style={{
                borderColor: '#002a52',
                overflowY: 'scroll',
                height: '75vh',
              }}
              extra={extra}
            >
              {data ? (
                <div className="view-info">{componentDetail}</div>
              ) : dataAll ? (
                dataAll
              ) : (
                <span
                  style={{
                    textAlign: 'center',
                    display: 'inherit',
                    color: 'grey',
                  }}
                >
                  {componentDetail}
                  No se han encontrado datos...
                </span>
              )}
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
