import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Button, Layout } from 'antd';
import useAuth from '../hooks/useAuth';
import MenuTop from '../components/MenuTop';
import MenuSider from '../components/MenuSider';
import AdminSignIn from '../pages/SignIn';
import { useDispatch, useSelector } from 'react-redux';
import './LayoutAdmin.scss';
import Turns from '../pages/Turns';
import Error404 from '../pages/Error404';

export default function LayoutAdmin(props) {
  const { routes } = props;
  const [menuCollapsed, setMenuCollapsed] = useState();
  const { Header, Content, Footer } = Layout;
  const { user, isLoading } = useAuth();
  const { organization, onlineTurns, tenantId } = useSelector(
    (store) => store.tenantData
  );

  window.document.title = window.location.href.includes('portalturnos')
    ? `Turnos | ${organization}`
    : `Portal Pacientes | ${organization}`;

  useEffect(() => {
    if (window.location.hash)
      window.location.pathname = window.location.hash.split('/')[2];
  }, [window.location]);

  if (!user && !isLoading) {
    if (window.location.href.includes('portalturnos') && onlineTurns) {
      return (
        <>
          <Route path={`/${tenantId}`} component={Turns} />
          <Redirect to={`/${tenantId}`} />
        </>
      );
    } else if (!window.location.href.includes('portalturnos')) {
      return (
        <>
          <Route path={'/login'} component={AdminSignIn} />
          <Redirect to="/login" />
        </>
      );
    } else {
      return <Error404 />;
    }
  }

  if (!isLoading) {
    return (
      <Layout>
        <MenuSider
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
        />
        <Layout className="layout-admin">
          <Header className="layout-admin__header" style={{ zIndex: 1 }}>
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>
          <Content className="layout-admin__content">
            <LoadRoutes routes={routes} />
          </Content>
          <Footer className="layout-admin__footer">
            <h5>AmpinaSoft - Contacto: soporte@ampinasoft.com - CopyRight ©</h5>
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
